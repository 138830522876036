<div class="loader-div" *ngIf="Loading">
    <div class="loader">
        <div class="one"></div>
        <div class="two"></div>
    </div>
</div>
    <section class="mainContent">
    <div class="locationInfo gContainerMain">
    <div class="stateName">State of Local News Outlook</div>
    
    <div class="stateDescription">
The local news crisis in the U.S. is deepening, with newspaper closures accelerating and the growth of new sources not keeping pace with what’s being lost. This vaporizing of local news and information has profound implications for communities, their inhabitants and ultimately the health of our democracy. Through data, maps, charts, analysis and predictive modeling, this report provides a detailed look at the state of local news today, both the risks that lie ahead and causes for optimism.

    </div>


    <div class="viewReportC" rel="noopener noreferrer"><a href="https://localnewsinitiative.northwestern.edu/research/state-of-local-news/report/" target="_blank">View 2023 Report</a></div>
    </div>
    
    <div class="InnerContent1 gContainerMain">
    
    
    <div class="TabSection">
        <div class="d-flex align-items-center my-2">
    
           <!-- <button mat-icon-button class="ScrollBtn" (click)="Scroll('left')">
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button> -->
            <ul class="nav nav-pills" id="pills-tab" role="tablist">
                <li class="nav-item " role="presentation" (click)="getTabName('AtRisk', 'Local News Landscape')">
                    <button class="nav-link show active" name="RiskCity" id="RiskCity-tab" data-bs-toggle="pill"
                        data-bs-target="#RiskCity" type="button" role="tab" aria-controls="RiskCity"
                        aria-selected="true">{{RadioOptionValue}}</button>
                </li>
                <li class="nav-item " role="presentation" (click)="getTabName('NewsPaper', 'Newspapers')">
                    <button class="nav-link" id="NewsPaper-tab" name="NewsPaper" data-bs-toggle="pill"
                        data-bs-target="#NewsPaper" type="button" role="tab" aria-controls="NewsPaper"
                        aria-selected="false">Newspapers</button>
                </li>
                <li class="nav-item" role="presentation" (click)="getTabName('DigitalSite', 'Digital Sites')">
                    <button class="nav-link" id="digitalSites-tab" name="DigitalSites" data-bs-toggle="pill"
                        data-bs-target="#digitalSites" type="button" role="tab" aria-controls="digitalSites"
                        aria-selected="false">Digital
                        Sites</button>
                </li>
                <li class="nav-item" role="presentation" (click)="getTabName('Ethnic', 'Ethnic Outlets')">
                    <button class="nav-link" id="EthinicVoice-tab" name="EthinicVoice" data-bs-toggle="pill"
                        data-bs-target="#EthinicVoice" type="button" role="tab" aria-controls="EthinicVoice"
                        aria-selected="false">Ethnic
                        Outlets</button>
                </li>
                <li class="nav-item" role="presentation" (click)="getTabName('Pb', 'Public Broadcasting')">
                    <button class="nav-link" id="publicProdCast-tab" name="PBStation" data-bs-toggle="pill"
                        data-bs-target="#publicProdCast" type="button" role="tab" aria-controls="publicProdCast"
                        aria-selected="false">Public Broadcasting</button>
                </li>
    
            </ul>
           <!-- <button mat-icon-button class="ScrollBtn" (click)="Scroll('right')">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>-->
    
        </div>
    
        <div class="tab-content" id="pills-tabContent">
            <div class="RiskParameterSection">
                <div class="chartInsideCaption">
				{{ParameterName !=='Local News Landscape' ? ParameterName : RadioOptionValue}}
				</div>
				 <!-- {{ParameterName}} -->
				
            
                <div class="chartInsideDesc" *ngIf="ParameterName !=='Local News Landscape'">
				
				  There are over 6,000 newspapers in the United States, including over 1,200 dailies and almost 4,800 weeklies.

				
                

					</div>
                    <div class="row" *ngIf="ParameterName ==='Local News Landscape'">
                        <div class="col-xl-8 col-sm-12 col-md-8">
                            <div class="chartInsideDesc">
                            
 There are 204 counties in the United States with no news outlets. There are 1,562 counties with only one.

             
                                 </div>
                        </div>
                        <div class="col-xl-4 col-sm-12 col-md-4">
                            <div class="radioContainer">
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                                    [(ngModel)]="RadioOptionValue" (change)="radioChange($event)">
                                    <mat-radio-button class="RadioButton" *ngFor="let Option of RadioOptions"
                                        [value]="Option.value">
                                        {{Option.Name}}
                                        <!-- <label for="" class="RadioButtonLable"> {{Option.Name}}</label> -->
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
            
            </div>
            <div class="tab-pane fade show active" id="RiskCity" role="tabpanel" aria-labelledby="RiskCity-tab">
    <div class="d-flex justify-content-between">
                        <div class="HeatMapRange" *ngIf="this.RadioOptionValue === 'Local News Landscape'">
                            <span class="noNewsPaper"> <span></span>No news outlets</span>
                            <span class="oneNewsPaper"><span></span>1 news outlet</span>
                            <span class="multipleNewsPaper"> <span></span>2 or more news outlets</span>
                        </div>
                        <div class="HeatMapRange" *ngIf="this.RadioOptionValue === 'Watchlist Counties'">
                            <span class="DesertLegend"> <span></span>Desert</span>
                            <span class="WatchlistLegend"><span></span>Watchlist</span>
                        </div>
                        <div class="HeatMapRange" *ngIf="this.RadioOptionValue === 'Metro Areas'">
                            <span class="LowDigital"> <span></span>Low Digital Replacement</span>
                            <span class="HighDegital"><span></span>High Digital Replacement</span>
                        </div>
                        <div class="HeatMapRange" *ngIf="this.RadioOptionValue === 'Local News Ecosystem Barometer'">
                            <span class="LowDigital"> <span></span>Less At Risk</span>
                            <span class="HighDegital"><span></span>More At Risk </span>
                        </div>
                       
                </div>
                <div class="MapContainer">
    
                 
                    
                    <main class="choropleth RiskCityMapSVG">
                        <div id="RiskCityMapSVG" ></div>
                        <div id="choropleth" class="d-flex justify-content-center"></div>
                        <div id="description"></div>
                    </main>
                   
                     <div class="zoom-func zoomingsection">
                             <button id="zoom-out" class="ZoomInClass" (click)="zoomOut()">	<img src="../../assets/Images/zoom-out.svg" alt=""></button>                                
    <button id="zoom-in" class="ZoomInClass" (click)="zoomIN()"><img src="../../assets/Images/zoom-in.svg" alt=""></button>
    <button id="zoom-in" class="ZoomInClass" (click)="ReStore()"> <img src="../../assets/Images/zoom-reset.svg" alt=""></button>
                                                          </div>
                </div>
				<div class="content">
                   Hover over a county on the map for more information. For further details, including lists of news outlets, click on a state in the map, or use the Explore any State drop-down menu below.

                </div>
            </div>
            <div class="tab-pane fade" id="NewsPaper" role="tabpanel" aria-labelledby="NewsPaper-tab">
                <div class="d-flex justify-content-between">
	   <div class="HeatMapRange">
    
                        <span class="noNewsPaper"><span></span>  0 Newspaper</span>
                        <span class="oneNewsPaper"> <span></span> 1 Newspaper</span>
                        <span class="multipleNewsPaper"> <span></span>2 or more Newspapers</span>
    
                        <!-- <span>0</span>
                        <div class="" id="colorRange" class="ColorRange"></div>
                        <span>1</span> -->
    
                    </div>
	
                  
	</div>
                <div class="MapContainer">
    
                    
                    
                    <main class="choropleth NewsPaperMapSVG">
                        <div id="NewsPaperMapSVG" class=""></div>
                        <div id="choropleth" class="d-flex justify-content-center"></div>
                        <div id="description"></div>
                    </main>
    
                    
    
                   <div class="zoom-func">
                           <button id="zoom-out" class="ZoomInClass" (click)="zoomOut()">	<img src="../../assets/Images/zoom-out.svg" alt=""></button>                                
    <button id="zoom-in" class="ZoomInClass" (click)="zoomIN()"><img src="../../assets/Images/zoom-in.svg" alt=""></button>
    <button id="zoom-in" class="ZoomInClass" (click)="ReStore()"> <img src="../../assets/Images/zoom-reset.svg" alt=""></button>
                                                      </div>
   
    
                </div>
				<div class="content">
                    Hover over a county on the map for more information. For further details, including lists of news outlets, click on a state in the map, or use the Explore any State drop-down menu below.

                </div>
				
            </div>
            <div class="tab-pane fade" id="digitalSites" role="tabpanel" aria-labelledby="digitalSitestab">
                <div class="d-flex justify-content-between">
	                 <div class="HeatMapRange">
    
                        <span class="noNewsPaper"> <span></span> 0 Digital Sites</span>
                        <span class="oneNewsPaper"> <span></span> 1 Digital Sites</span>
                        <span class="multipleNewsPaper"><span></span> 2 or more Digital Sites</span>
    
    
                     </div>
                     
	</div>
                <div class="MapContainer">
                    <!-- <div class="mapTitle">Digital Sites</div> -->
                    
                    
                    <main class="choropleth digitalSitesMapSVG">
                        <div id="digitalSitesMapSVG" ></div>
                        <div id="choropleth" class="d-flex justify-content-center"></div>
                        <div id="description"></div>
                    </main>
                   
                  <div class="zoom-func">
                          <button id="zoom-out" class="ZoomInClass" (click)="zoomOut()">	<img src="../../assets/Images/zoom-out.svg" alt=""></button>                                
    <button id="zoom-in" class="ZoomInClass" (click)="zoomIN()"><img src="../../assets/Images/zoom-in.svg" alt=""></button>
    <button id="zoom-in" class="ZoomInClass" (click)="ReStore()"> <img src="../../assets/Images/zoom-reset.svg" alt=""></button>
                                                      </div>
                   
    
                </div>
				<div class="content">
                    Hover over a county on the map for more information. For further details, including lists of news outlets, click on a state in the map, or use the Explore any State drop-down menu below.

                </div>
				
            </div>
            <div class="tab-pane fade" id="EthinicVoice" role="tabpanel" aria-labelledby="EthinicVoice-tab">
                <div class="d-flex justify-content-between">
                  <div class="HeatMapRange">
                
                        <span class="noNewsPaper"><span></span> 0 Ethnic Outlets</span>
                        <span class="oneNewsPaper"><span></span> 1 Ethnic Outlets</span>
                        <span class="multipleNewsPaper"> <span></span> 2 or more Ethnic Outlets</span>
    
                    </div>
				   
                </div>
				<div class="MapContainer">
                    <!-- <div class="mapTitle">Ethnic Voices</div> -->
                   
                    
					
					
                    <main class="choropleth EthnicVoiceMapSVG">
                        <div id="EthnicVoiceMapSVG" ></div>
                        <div id="choropleth" class="d-flex justify-content-center"></div>
                        <div id="description"></div>
                    </main>
                   
				    <div class="zoom-func">
              <button id="zoom-out" class="ZoomInClass" (click)="zoomOut()">	<img src="../../assets/Images/zoom-out.svg" alt=""></button>                                
    <button id="zoom-in" class="ZoomInClass" (click)="zoomIN()"><img src="../../assets/Images/zoom-in.svg" alt=""></button>
    <button id="zoom-in" class="ZoomInClass" (click)="ReStore()"> <img src="../../assets/Images/zoom-reset.svg" alt=""></button>
                                                      </div>
                  
  
                </div>
				
				<div class="content">
                  Hover over a county on the map for more information. For further details, including lists of news outlets, click on a state in the map, or use the Explore any State drop-down menu below.

                </div>
				
				
            </div>
            <div class="tab-pane fade" id="publicProdCast" role="tabpanel" aria-labelledby="publicProdCast-tab">
                <div class="d-flex justify-content-between">
	              <div class="HeatMapRange">
                
                        <span class="noNewsPaper"><span></span> 0 Public Broadcasting</span>
                        <span class="oneNewsPaper"><span></span> 1 Public Broadcasting</span>
                        <span class="multipleNewsPaper"><span></span> 2 or more Public Broadcasting</span>
    
                    </div>
                    
	</div>
                <div class="MapContainer">
                    <!-- <div class="mapTitle">Public Broadcasting</div> -->
                   
                    
					
					
                    <main class="choropleth PubliCProdCastMapSVG">
                        <div id="PubliCProdCastMapSVG" ></div>
                        <div id="choropleth" class="d-flex justify-content-center"></div>
                        <div id="description"></div>
                    </main>
                  
                  <div class="zoom-func">
            <button id="zoom-out" class="ZoomInClass" (click)="zoomOut()">	<img src="../../assets/Images/zoom-out.svg" alt=""></button>                                
    <button id="zoom-in" class="ZoomInClass" (click)="zoomIN()"><img src="../../assets/Images/zoom-in.svg" alt=""></button>
    <button id="zoom-in" class="ZoomInClass" (click)="ReStore()"> <img src="../../assets/Images/zoom-reset.svg" alt=""></button>
                                                      </div>
				  
				  
   
                </div>
				
				<div class="content">
                   Hover over a county on the map for more information. For further details, including lists of news outlets, click on a state in the map, or use the Explore any State drop-down menu below.

                </div>
				
				
            </div>
        </div>
    
    </div>

    </div>

    <div class="stateChoose">
        <span class="StateSelectText">Explore any state:</span>
        <mat-select [formControl]="disableSelect" class="StateSelector">
            <mat-option value="option2" disabled>-- Select State --</mat-option>
            <mat-option [value]="state.StateAbbr" *ngFor="let state of StateNameList"
                (onSelectionChange)="stateChange($event,state)">{{state.StateName}}</mat-option>
        </mat-select>
    </div>
    
    <div class="InnerContent gContainerMain2">
       <!-- <div class="hrSeparator">
    
        </div>-->
    <div class="ChartSection">
        <div class="chartList d-flex align-items-center flex-wrap g-3">
            <div class="col-12 col-sm-6 col-md-6 col-xl-6 d-flex align-items-center justify-content-start">
                <span class="chartCaption">Local news <br>by the numbers</span>
            </div>        
            <div class="col-12 col-sm-12 col-md-6 col-xl-6 d-flex align-items-center justify-content-start">
			 <img src="../../assets/Images/total-number-newspapers.svg" alt="">
               
       
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-xl-6 d-flex align-items-center justify-content-start outlet">
                <img src="../../assets/Images/digital-news-ecosystem.svg" alt="">

            </div>
            <div class="col-12 col-sm-12 col-md-6 col-xl-6 d-flex align-items-center justify-content-start">
                <img src="../../assets/Images/newspaper-employment.svg" alt="">

            </div>
        </div>
    </div>
    
    <div class="leaderShipSection">
        <div class="chartHeader ">
            <span class="chartCaption my-3">Thought Leadership</span>
        </div>
        <div class="row cardSection">

            <div *ngFor="let data of carousalData ; index as i" [ngClass]="{'col-xl-12 ': i ===0 , 'col-xl-4 col-md-6 ': i !==0}" class="col-12 ">
                <div class="carousalCard" *ngIf="i==0" [ngClass]="{'d-md-flex col-12 d-none': i == 0}" >
                    <div class="cardImg" [ngClass]="{'col-12 col-md-6': i == 0}" [ngStyle]="{'height': i == 0 ? '' : ''}">
                        <img src="{{data.cardImg}}" alt="" >
                    </div>
                    <div class="cardContent" [ngClass]="{'col-12 col-md-6': i == 0}">
                       
                        <div class="cardTitle mt-4">{{data.cardTitle}}</div>
                        <div class="cardDescription">{{data.cardDesc}}</div>
                        <button class="cardActionBtn" *ngIf="data.cardType === 'report'">
                            Download
                            <img src="../../assets/Images/downloadIcon.svg" alt="">
                     
                        </button>
                        <button class="cardActionBtn" *ngIf="data.cardType === 'learnmore'">Learn More
                            <span>
                                <img src="../../assets/Images/right-arrow.png" alt="" >

                            </span>
                        </button>
                        <div class="cardUser mt-4">
                            <div class="user">
                                <div style="color:#254B8C;">{{data.readCaption}}</div>
                               <div class="bg-black rounded-circle" style="width: 0.5rem; height: .5rem;"></div>
                                <div>{{data.postTime}}</div>
                            </div>
                        </div>
        
                    </div>
                </div>
                <div class="carousalCard" [ngClass]="{'d-md-none d-block': i == 0}">
                    <div class="cardImg">
                        <img src="{{data.cardImg}}" alt="" >
                    </div>
                    <div class="cardContent" >
                       
                        <div class="cardTitle mt-3">{{data.cardTitle}}</div>
                        <div class="cardDescription">{{data.cardDesc}}</div>
                        <button class="cardActionBtn" *ngIf="data.cardType === 'report'">Download
                            <span>
                                <img src="../../assets/Images/downloadIcon.svg" alt="" >
                            </span>
                        </button>
                        <button class="cardActionBtn" *ngIf="data.cardType === 'learnmore'">
                            View
                            <img src="../../assets/Images/arrow-circle-right.svg" alt="" >
                        </button>
                        <div class="cardUser mt-4">
                            <div class="user">
                                <div style="color:#254B8C;">{{data.readCaption}}</div>
                                 <div class="bg-black rounded-circle" style="width: 0.5rem; height: .5rem;"></div>
                                <div>{{data.postTime}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
</div>
<!-- <div class="InnerContent gContainerMain3">
    <div class="HomeEndSection ">
        <div class="chartHeader ">
            <span class="chartCaption " style="font-size: 35px ">Check for Latest <strong style="font-size: 35px " class="underLineCaption">Updates</strong></span>
        </div>
        <div class="row HomeEnddesc g-1">
            <div class="col-6">
                <p style="font-weight: 500;color: #000;font-size:22px;">
                    Lorem ipsum dolor sit amet goals and latest
                    updates text highlights Northwestern Medill
                    Description update here Lorem ipsum dolor sit
                    amet goals and latest updates text highlights
                    Northwestern Medill Description update here
                </p>
                <button class="updateButton">
                    <img src="../../assets/Images/Cloud Icon.svg" alt="" srcset="" class=" ">
                    Check for Updates
                </button>
            </div>
            <div class="col-6 d-flex align-items-center flex-wrap justify-content-center">
                 <div class="four col-md-3">
                    <div class="c-box">
                        <img src="../../assets/Images/research.svg" alt="" class="gp-icon">
                        <p>Research</p>
                    </div>
                </div>
                <div class="four col-md-3">
                    <div class="c-box">
                        <img src="../../assets/Images/articles.svg" alt="" class="gp-icon">
                        <p>Articles</p>
                    </div>
                </div>
                <div class="four col-md-3">
                    <div class="c-box">
                        <img src="../../assets/Images/resources.svg" alt="" class="gp-icon">
                        <p>Resources</p>
                    </div>
                </div>

                <div class="col-12 d-flex align-items-center justify-content-center">
                    <img src="../../assets/Images/image 25.png" width="400" alt="" srcset="" class=" ">
                </div>
            </div>
        </div>
    </div>
</div> -->
    <!-- 
    <div class="CarouselSection">
    <div class="row">
    
        <div *ngFor="let data of carousalData " class="col-12 col-md-6 col-xl-3">
    
            <div class="carousalCard">
                <div class="cardImg">
                    <img src="{{data.cardImg}}" alt="">
                </div>
                <div class="cardContent">
                    <div class="cardTitle">{{data.cardTitle}}</div>
                    <div class="cardDescription">{{data.cardDesc}}</div>
                    <button class="cardActionBtn" *ngIf="data.cardType === 'report'">DOWNLOAD REPORT
                        <span>
                            <img src="../../assets/Images/download (2).png" alt="" style="height: 24px;">
                        </span>
                    </button>
                    <button class="cardActionBtn" *ngIf="data.cardType === 'learnmore'">LEARN MORE
                        <span>
                            <img src="../../assets/Images/right-arrow.png" alt="" style="height: 18px;">
    
                        </span>
                    </button>
    
                </div>
            </div>
        </div>
    </div>
    
    </div> -->
    
    
    
    <!-- <div class="HomeEndSection">
    
    
    <img src="../../assets/Images/HomeEndlogo.png" alt="" srcset="" class=" ">
    <button class="updateButton">Check for Updates</button>
    </div> -->
    </section>