     <section class="mainContent">
	
	<div class="locationInfo gContainerMain">
    <div class="stateName">Bright Spots in the Local News Landscape</div>
    
    <div class="stateDescription">
      Highlighting local news startups and some of the business models most successfully navigating 21st century challenges
    </div>


   </div>
   
	 <div class="InnerContent1 gContainerMain">
    <div class="content">
      <p>*Press “play” to see local news startups as they occurred over the last five years. Click on a star to read about outlets with promising business models for navigating 21st century challenges. Use the News deserts map button to activate a layer showing news deserts by county across the country. You can also zoom in for more detail.
      </p>
	
	
	<iframe
  id="inlineFrameExample"
  title="Inline Frame Example"
  width="100%"
  src="https://app.powerbi.com/view?r=eyJrIjoiZjU1MmYzMjAtMzNiNi00NDQ1LTllNDItODA3MWM3ZjM2YzU0IiwidCI6Ijk0MjYwZjAzLTA3OTMtNDg0YS05MWNmLWJlYmU1ODQzMTliYyIsImMiOjEwfQ%3D%3D">
  
</iframe>
	<div class="interactive">
	<p>
	This year’s State of Local News Report offers hope as it presents 17 outlets that provide their communities with excellent reporting essential to democracy while progressing in the quest for stable, sustainable business models.
	
	</p>
	
	   <p>The interactive Bright Spots map features a variety of outlets across the country: for-profit and nonprofit; legacy print and 21st century digital; and urban, suburban, small-town and rural. Some serve wealthy areas; others serve communities with high poverty rates and low income. Some are statewide in focus; others serve neighborhoods. 
</p>
<p>All have key features that set them apart, which are described in pop-up features plus links to more in-depth special reports or Q&As with executives from the Bright Spot outlets. These pieces offer insights that could prove helpful to other news and civic leaders hoping to revive reporting in their communities. 
</p>
<p>To be included as a Bright Spot, an outlet must have served its local audience for at least five years with journalism that holds elected representatives and others in power accountable. This is not intended to be an exhaustive list, and readers are encouraged to nominate other news outlets to be considered for a future Bright Spots map.
</p>


<p>The map also highlights 164 news startups that are less than five years old.</p>  
<p>The local news business models having the most success navigating today’s economic challenges share a number of traits:</p>
<ul>
<li>All 17 are privately held and controlled. None of the for-profits are traded on public markets or paying high-interest debts to investment funds or hedge funds.
</li>
<li>Sixteen of the 17 are locally owned.</li>
<li>All 17 have retained large reporting staffs relative to their markets. The legacy print metro newsrooms typically employ from 40% to 200% more journalists than peer newsrooms serving markets of similar size.  
</li>
<li>Both for-profit and nonprofit leaders emphasize the importance of listening to their communities, building and maintaining local relationships, and providing excellent local customer service for advertisers, sponsors and donors as well as for readers, listeners, viewers, members or subscribers. 
 </li>
 </ul>
 
 <p>In legacy print markets, the Bright Spots indicate that the benefits of private ownership committed to community service have surpassed the past cost-saving rewards of chain ownership, with most of the nation’s city dailies now in the hands of Gannett, Lee, Alden and McClatchy. Unencumbered by large debts taken on by chains to expand and achieve economies of scale, these successful metro newsrooms invest in news, customer service and marketing and are able to adjust to changing consumer habits. 
 </p>
 
 <p>
 Nonprofit newsrooms extol the virtues of listening to people in the community about the news they need most and about the best ways to deliver that news and information to them. The nonprofits that last typically have been able to prove their value to local and regional funders and members rather than relying solely on large national foundations that may have provided their startup seed funding. 
 </p>
 
 <p>
 In general, the nonprofits provide all news and information for free but encourage those who value their work to support them as members. The for-profits keep most of their news and information behind a subscription wall, with exceptions for some public service and grant-supported stories.  
 </p>
 <p>
 Yet there are exceptions. AL.com is a for-profit operation that has transformed itself from a publisher of three daily newspapers in Alabama to a statewide digital provider with free news content. AL.com also has won four Pulitzer prizes in recent years, including two in 2023. 
 </p>
<p>
Newsletters have become important to many Bright Spots as they offer readers summaries of relevant news that help them navigate life and quickly find what they need to know. This is one way successful operations are combatting news fatigue.
</p>

<p>
Being present where public business is conducted remains important, as high-impact stories often arise from tips and questions raised quietly in settings that no longer receive the coverage they once did. In some places concerned citizens are being trained to represent the public at meetings and hearings, relaying what they observe to beat reporters or editors. 
</p>

<p>
“People in the community often see things differently and ask different questions than trained reporters do,” Detroit’s Outlier Media Executive Director Candice Fortman says. “We had a big six-part investigative series on the Detroit Housing Commission that came directly out of <a href="https://outliermedia.org/detroit-housing-commission-investigation-rukiya-colvin/" target="_blank">a question a Documenter asked at a meeting</a>. It stayed with our housing reporter for two years, where over time the reporter gained sources and evidence that made it an investigation that has had real impact.”
</p>

<p>
The leaders of the 17 Bright Spots all mention the importance of pivoting to meet their communities’ needs with news of high value, which is necessary to gain support through subscriptions, memberships, donations and viewership. Each outlet is finding unique ways to accomplish these goals. All say they are still searching for a pathway to sustained growth. 
</p>

<p>
Digital subscription momentum slowed in 2023, which is a concern given this has been a key growth lever for successful legacy print operations. Inflation appears to have caused many consumers to examine their overall spending on subscriptions, which include streaming services for entertainment and sports. Many outlets are finding it hard to grow in areas where they have deep market penetration and are seeking to expand either geographically or with topics of interest to a broader audience. 
</p>

<p>Collaboration among news outlets has been key to filling news deserts and the huge gaps in coverage left by the loss of reporters in the field. </p>

<p>
The nonprofit Lenfest Institute of Philadelphia, which owns the Inquirer and helped create the Spotlight PA news collaborative, has an added mission of helping develop sustainable news business models beyond the markets it serves. Lenfest CEO Jim Friedlich invites civic and local news leaders who wish to strengthen public service reporting in their communities to contact the Institute. 
</p>

<p>
“There are numerous examples or archetypes of how civic leaders and funders can help local journalism not only survive but thrive,” Friedlich says.  “We have worked with civic leaders in Los Angeles, New Jersey and Minneapolis who have purchased their local newspaper and dedicated it to public services. We have helped launch start-up local news sites in Delaware, Baltimore, Omaha, Harrisburg and New York City. We have advised public media in Chicago and Lancaster (Penn.) on mergers with their legacy local metro news organizations.
</p>

<p>
“Every opportunity is different . . .  Call or email and let us help.”  
</p>
<a href="">Link to Q&A with Jim Friedlich.</a> 
<p>Lenfest: (215) 854-5600, <a href="mailto:info@lenfestinstitute.org">info@lenfestinstitute.org</a><br>
Author: George Stanley, Penelope Abernathy and Greg Burns</p>
<br>
</div>



	</div>
	</div>
	
	
	
</section>

 <app-footer from="NationalPage"></app-footer>
