import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brightspots',
  templateUrl: './brightspots.component.html',
  styleUrls: ['./brightspots.component.scss']
})
export class BrightspotsComponent implements OnInit {



  ngOnInit() {
    document.getElementById('home')?.classList.remove('active');
    document.getElementById('newspaper')?.classList.remove('active');
    document.getElementById('StateofLocal')?.classList.remove('active');
    document.getElementById('brightspot')?.classList.add('active');

  }
}
