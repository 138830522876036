
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {


  @Input() from = '';


  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 100,
    margin: 16,
    navText: ['', ''],
    items: 4,
    // autoplay:true,
    responsive: {
      0: {
        items: 2
      },
      600: {
        items: 4
      },
      768: {
        items: 4
      },
      992: {
        items: 6
      },
      1200: {
        items: 6
      },
    },


  }


  SponsoresArr = [
    {
      Image: '/projects/state-of-local-news/explore/assets/Images/Footer1.png',
      className: 'footerImg Img1'
    },
    {
      Image: '/projects/state-of-local-news/explore/assets/Images/content.svg',
      className: 'footerImg Img2'

    },
    {
      Image: '/projects/state-of-local-news/explore/assets/Images/Joyce.svg',
      className: 'footerImg Img3'

    },
    {
      Image: '/projects/state-of-local-news/explore/assets/Images/footer4.png',
      className: 'footerImg Img4'

    },
    {
      Image: '/projects/state-of-local-news/explore/assets/Images/Microsoft.svg',
      className: 'footerImg Img5'

    },
    {
      Image: '/projects/state-of-local-news/explore/assets/Images/Myrta.svg',
      className: 'footerImg Img6'

    },
    {
      Image: '/projects/state-of-local-news/explore/assets/Images/Mark-Ferguson.svg',
      className: 'footerImg Img6'

    },
    {
      Image: '/projects/state-of-local-news/explore/assets/Images/SNPA.svg',
      className: 'footerImg Img6'

    },
 
    
  ]


  GotoHome(){
     window.open('https://localnewsinitiative.northwestern.edu/newsletter/', '_blank');
  }


  FooterLinks(LinkName:any){
    if(LinkName ==='Research'){
      const url = 'https://localnewsinitiative.northwestern.edu/research/';
    window.open(url, '_blank');
    }else if(LinkName ==='Articles'){
	
	 const url = 'https://localnewsinitiative.northwestern.edu/posts/';
    window.open(url, '_blank');

    }else if(LinkName ==='Resources'){
	
	 const url = 'https://localnewsinitiative.northwestern.edu/resources/';
    window.open(url, '_blank');

    }

  }
}
