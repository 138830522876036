import { Component, OnInit } from '@angular/core';
import * as d3 from "d3";
import * as topojson from 'topojson-client';
import { HttpClient } from '@angular/common/http';
import { DecimalPipe, CurrencyPipe } from '@angular/common';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { LNIService } from 'app/Service/lni.service';
import { Observable, count } from 'rxjs';
import { FormControl } from '@angular/forms';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { Chart, registerables } from 'chart.js';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { SubSink } from 'subsink';
@Component({
  selector: 'app-national-outlets',
  templateUrl: './national-outlets.component.html',
  styleUrls: ['./national-outlets.component.scss'],
  providers: [CurrencyPipe, DecimalPipe],
})
export class NationalOutletsComponent {

  disableSelect = new FormControl('option2');
  faCoffee: any = faCoffee;

  private subs = new SubSink();

  StateDataArr: any = [];


  RadioOptionValue: any = 'Local News Landscape';


  RadioOptions: any[] = [
    {
      Name: 'Local News Landscape',
      value: 'Local News Landscape',
    },
    {
      Name: 'Watch List Counties',
      value: 'Watch List Counties',
    },
    {
      Name: 'Changes in Metro Area News',
      value: 'Metro Areas',
    },
    {
      Name: 'Local News Ecosystem Barometer',
      value: 'Local News Ecosystem Barometer',
    },
  ];

  TabIndex = 1
  // MapGeoJsonData: any = [];
  width: number = 0;
  height: number = 0;
  Zoom: string = '';
  Top: string = '';
  left: string = '';

  Loading = false
  TabName: any = 'Local News Landscape';
  CurrentTabName: any = '';

  ParameterName: any = 'Local News Landscape';

  chart1Completed = false
  chart2Completed = false
  chart3Completed = false
  chart4Completed = false
  chart5Completed = false
  geoData: any = [];
  StateBoundariesGeoJsonData: any = null;

  constructor(
    public router: Router,
    private lniService: LNIService,
    private http: HttpClient,
    private params: ActivatedRoute,
    private decimalPipe: DecimalPipe,
    public currencyPipe: CurrencyPipe,) {
    Chart.register(...registerables);
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 100,
    margin: 16,
    navText: ['', ''],
    items: 4,
    // autoplay:true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      768: {
        items: 2
      },
      992: {
        items: 3
      },
      1200: {
        items: 4
      },
    },
    nav: false
  }

  BaseURL = '/projects/state-of-local-news/explore/'

  carousalData = [
    {
      posterName: 'Penny',
      cardTitle: '2023 Report',
      cardDesc: "New findings on news deserts, shifting business models and what needs to be done to sustain local journalism.",
      cardImg: `${this.BaseURL}assets/Images/2023-report.png`,
      url: 'https://localnewsinitiative.northwestern.edu/projects/state-of-local-news/2023/report/',
      cardType: 'report'
    },
    {
      posterName: 'Staff',
      cardTitle: 'Previous Reports',
      cardDesc: "Learn more about the changing news landscape by reading reports from previous years.",
      cardImg: `${this.BaseURL}assets/Images/previous-reports.png`,
      url: 'https://localnewsinitiative.northwestern.edu/projects/state-of-local-news/',
      cardType: 'viewmore'
    },
    {
      posterName: 'Medill Staff',
      cardTitle: 'Research Insights',
      cardDesc: "Interviews with researchers examining issues related to the information needs of communities and developments in local journalism.",
      cardImg: `${this.BaseURL}assets/Images/research.png`,
      url: 'https://localnewsinitiative.northwestern.edu/projects/state-of-local-news/research-insights/',
      cardType: 'viewmore'
    },
    {
      posterName: 'Medill Staff',

      cardTitle: 'Newsletter',
      cardDesc: "To receive quarterly updates from the State of Local News Project, please use the link below to join our mailing list.",
      cardImg: `${this.BaseURL}assets/Images/Newsletter.png`,
      url: 'https://localnewsinitiative.northwestern.edu/newsletter/',
      cardType: 'learnmore'
    }

  ]



  StateNameList: any = [
    {
      "StateCode": '01',
      "StateName": "Alabama",
      "StateAbbr": "AL"
    },
    {
      "StateCode": '02',
      "StateName": "Alaska",
      "StateAbbr": "AK"
    },
    {
      "StateCode": '04',
      "StateName": "Arizona",
      "StateAbbr": "AZ"
    },
    {
      "StateCode": '05',
      "StateName": "Arkansas",
      "StateAbbr": "AR"
    },
    {
      "StateCode": '06',
      "StateName": "California",
      "StateAbbr": "CA"
    },
    {
      "StateCode": '08',
      "StateName": "Colorado",
      "StateAbbr": "CO"
    },
    {
      "StateCode": '09',
      "StateName": "Connecticut",
      "StateAbbr": "CT"
    },
    {
      "StateCode": '10',
      "StateName": "Delaware",
      "StateAbbr": "DE"
    },
    {
      "StateCode": 11,
      "StateName": "District of Columbia",
      "StateAbbr": "DC"
    },
    {
      "StateCode": 12,
      "StateName": "Florida",
      "StateAbbr": "FL"
    },
    {
      "StateCode": 13,
      "StateName": "Georgia",
      "StateAbbr": "GA"
    },
    {
      "StateCode": 15,
      "StateName": "Hawaii",
      "StateAbbr": "HI"
    },
    {
      "StateCode": 16,
      "StateName": "Idaho",
      "StateAbbr": "ID"
    },
    {
      "StateCode": 17,
      "StateName": "Illinois",
      "StateAbbr": "IL"
    },
    {
      "StateCode": 18,
      "StateName": "Indiana",
      "StateAbbr": "IN"
    },
    {
      "StateCode": 19,
      "StateName": "Iowa",
      "StateAbbr": "IA"
    },
    {
      "StateCode": 20,
      "StateName": "Kansas",
      "StateAbbr": "KS"
    },
    {
      "StateCode": 21,
      "StateName": "Kentucky",
      "StateAbbr": "KY"
    },
    {
      "StateCode": 22,
      "StateName": "Louisiana",
      "StateAbbr": "LA"
    },
    {
      "StateCode": 23,
      "StateName": "Maine",
      "StateAbbr": "ME"
    },
    {
      "StateCode": 24,
      "StateName": "Maryland",
      "StateAbbr": "MD"
    },
    {
      "StateCode": 25,
      "StateName": "Massachusetts",
      "StateAbbr": "MA"
    },
    {
      "StateCode": 26,
      "StateName": "Michigan",
      "StateAbbr": "MI"
    },
    {
      "StateCode": 27,
      "StateName": "Minnesota",
      "StateAbbr": "MN"
    },
    {
      "StateCode": 28,
      "StateName": "Mississippi",
      "StateAbbr": "MS"
    },
    {
      "StateCode": 29,
      "StateName": "Missouri",
      "StateAbbr": "MO"
    },
    {
      "StateCode": 30,
      "StateName": "Montana",
      "StateAbbr": "MT"
    },
    {
      "StateCode": 31,
      "StateName": "Nebraska",
      "StateAbbr": "NE"
    },
    {
      "StateCode": 32,
      "StateName": "Nevada",
      "StateAbbr": "NV"
    },
    {
      "StateCode": 33,
      "StateName": "New Hampshire",
      "StateAbbr": "NH"
    },
    {
      "StateCode": 34,
      "StateName": "New Jersey",
      "StateAbbr": "NJ"
    },
    {
      "StateCode": 35,
      "StateName": "New Mexico",
      "StateAbbr": "NM"
    },
    {
      "StateCode": 36,
      "StateName": "New York",
      "StateAbbr": "NY"
    },
    {
      "StateCode": 37,
      "StateName": "North Carolina",
      "StateAbbr": "NC"
    },
    {
      "StateCode": 38,
      "StateName": "North Dakota",
      "StateAbbr": "ND"
    },
    {
      "StateCode": 39,
      "StateName": "Ohio",
      "StateAbbr": "OH"
    },
    {
      "StateCode": 40,
      "StateName": "Oklahoma",
      "StateAbbr": "OK"
    },
    {
      "StateCode": 41,
      "StateName": "Oregon",
      "StateAbbr": "OR"
    },
    {
      "StateCode": 42,
      "StateName": "Pennsylvania",
      "StateAbbr": "PA"
    },
    {
      "StateCode": 44,
      "StateName": "Rhode Island",
      "StateAbbr": "RI"
    },
    {
      "StateCode": 45,
      "StateName": "South Carolina",
      "StateAbbr": "SC"
    },
    {
      "StateCode": 46,
      "StateName": "South Dakota",
      "StateAbbr": "SD"
    },
    {
      "StateCode": 47,
      "StateName": "Tennessee",
      "StateAbbr": "TN"
    },
    {
      "StateCode": 48,
      "StateName": "Texas",
      "StateAbbr": "TX"
    },
    {
      "StateCode": 49,
      "StateName": "Utah",
      "StateAbbr": "UT"
    },
    {
      "StateCode": 50,
      "StateName": "Vermont",
      "StateAbbr": "VT"
    },
    {
      "StateCode": 51,
      "StateName": "Virginia",
      "StateAbbr": "VA"
    },
    {
      "StateCode": 53,
      "StateName": "Washington",
      "StateAbbr": "WA"
    },
    {
      "StateCode": 54,
      "StateName": "West Virginia",
      "StateAbbr": "WV"
    },
    {
      "StateCode": 55,
      "StateName": "Wisconsin",
      "StateAbbr": "WI"
    },
    {
      "StateCode": 56,
      "StateName": "Wyoming",
      "StateAbbr": "WY"
    }
  ]

  public chart1: any;
  public chart2: any;


  ViewWidth = 0
  ViewHeight = 0;
  ViewLeft = 0

  MapContainerWidth = 0;

  routerValue = ''

  ngOnInit() {

    let quary: any = this.params.snapshot;
    // console.log(this.params);

    setTimeout(() => {


      const contents = document.querySelectorAll('tab-pane')
      contents.forEach(element => {
        element.classList.remove('show')
        element.classList.remove('active')

      });
      const tabNames = document.querySelectorAll('nav-link')
      tabNames.forEach(element => {
        // console.log(element);
        element.classList.remove('active')

      });





      this.routerValue = quary.routeConfig.path;
      // console.log(quary.routeConfig.path);
      this.CurrentTabName = quary.routeConfig.path;

      let Tabindex = 0


      if (this.routerValue === 'localnewslandscape' || this.routerValue === 'watchlist' || this.routerValue === 'metroareanews' || this.routerValue === 'localnewsecosystembarometer') {


        document.getElementById('localnewslandscape')?.classList.add('active')
        const tabContent: any = document.getElementsByName('localnewslandscape')
        this.TabName = 'Local News Landscape'

        tabContent[0]?.classList.add('active')
        tabContent[0]?.classList.add('show');
        this.MapId = 'RiskCityMapSVG';
        Tabindex = 0
        this.ParameterName = 'Local News Landscape'

        // setTimeout(() => {


        if (this.routerValue == 'watchlist') {
          this.RadioOptionValue = 'Watch List Counties'
          this.radioChange({ value: 'Watch List Counties' })
        } else if (this.routerValue == 'metroareanews') {
          this.RadioOptionValue = 'Metro Areas'
          this.radioChange({ value: 'Metro Areas' })
        }
        else if (this.routerValue == 'localnewsecosystembarometer') {
          this.RadioOptionValue = 'Local News Ecosystem Barometer'
          this.radioChange({ value: 'Local News Ecosystem Barometer' })
        } else {

        }
        // }, 500);
      }
      else if (this.routerValue === 'newspapers') {
        document.getElementById(this.routerValue)?.classList.add('active')
        const tabContent: any = document.getElementsByName(this.routerValue)

        tabContent[0]?.classList.add('active')
        tabContent[0]?.classList.add('show');
        this.TabName = 'Newspaper Count'
        this.MapId = 'NewsPaperMapSVG';
        this.ParameterName = 'Newspapers'
        Tabindex = 1
      }
      else if (this.routerValue === 'digitalsites') {
        document.getElementById(this.routerValue)?.classList.add('active')
        const tabContent: any = document.getElementsByName(this.routerValue)
        this.TabName = 'Digital Sites Count'
        tabContent[0]?.classList.add('active')
        tabContent[0]?.classList.add('show');
        this.MapId = 'digitalSitesMapSVG'
        this.ParameterName = 'Digital Sites'
        Tabindex = 2

      } else if (this.routerValue === 'ethnicoutlets') {
        document.getElementById(this.routerValue)?.classList.add('active')
        const tabContent: any = document.getElementsByName(this.routerValue)
        this.TabName = 'Ethnic Outlets Count'
        tabContent[0]?.classList.add('active')
        tabContent[0]?.classList.add('show');
        this.MapId = 'EthnicVoiceMapSVG'
        this.ParameterName = 'Ethnic Outlets'
        Tabindex = 3

      } else if (this.routerValue === 'publicbroadcasting') {
        document.getElementById(this.routerValue)?.classList.add('active')
        const tabContent: any = document.getElementsByName(this.routerValue)
        this.TabName = 'Public Broadcasting Count'
        tabContent[0]?.classList.add('active')
        tabContent[0]?.classList.add('show');
        this.MapId = 'PubliCProdCastMapSVG'
        this.ParameterName = 'Public Broadcasting'
        Tabindex = 4

      }



      document.getElementById('newspaper')?.classList.remove('active');
      document.getElementById('brightspot')?.classList.remove('active');
      document.getElementById('StateofLocal')?.classList.remove('active');
      document.getElementById('home')?.classList.add('active');
      const div: any = document.getElementsByClassName('MapContainer'); // Get the div element by its ID
      const w = div[Tabindex].offsetWidth;

      this.MapContainerWidth = w

      let MapLengends: any = document.getElementById("pills-tabContent")

      if (w > 250 && w < 600) {
        const width = MapLengends.offsetWidth
        const height = 740
        this.Zoom = 'scale(1)';
        this.Top = '20px';
        this.left = '5%';
        this.width = w
        this.height = 300
        this.ViewWidth = w
        this.ViewHeight = 750
        this.ViewLeft = 250


      } else if (w > 601 && w < 800) {
        const width = MapLengends.offsetWidth
        const height = 740
        this.Zoom = 'scale(1)';
        this.Top = '20px';
        this.left = '5%';
        this.width = 750
        this.height = 500
        this.ViewWidth = 900
        this.ViewHeight = 750
        this.ViewLeft = 0

      }

      else if (w > 801 && w < 1000) {
        const width = MapLengends.offsetWidth
        const height = 550
        this.Zoom = 'scale(1)';
        this.Top = '20px';
        this.left = '0';
        this.width = w
        this.height = 550
        this.ViewWidth = w
        this.ViewHeight = 700;
        this.ViewLeft = 0


      }
      else if (w > 1001 && w < 1200) {
        const width = 600;
        const height = 400
        this.Zoom = 'scale(1)';
        this.Top = '20px';
        this.left = '0';

        this.ViewLeft = 0

        this.width = w + 300
        this.height = 750
        this.ViewWidth = w
        this.ViewHeight = 700

      }
      else if (w > 1201) {
        const width = MapLengends.offsetWidth
        const height = 740
        this.Zoom = 'scale(1)';
        this.Top = '20px';
        this.left = '5%';
        this.width = w + 700
        this.height = 950
        this.ViewWidth = w
        this.ViewHeight = 700;
        this.ViewLeft = 0

      }

      this.Loading = true;
      if (Tabindex === 0) {
        this.chart1Completed = true
      }
      else if (Tabindex === 1) {
        this.chart2Completed = true
      }
      else if (Tabindex === 2) {
        this.chart3Completed = true
      }
      else if (Tabindex === 3) {
        this.chart4Completed = true
      }
      else if (Tabindex === 4) {
        this.chart5Completed = true;
      }

      if (this.routerValue !== 'watchlist' && this.routerValue !== 'metroareanews' && this.routerValue !== 'localnewsecosystembarometer') {

        this.getPaperDetailes();

        this.loadTabContent(Tabindex)
      }
    }, 10);
  }
  getTabName(Name: any, ParameterName: any) {


    // this.ParameterName = ParameterName
    // this.zoomLevel = 1
    if (Name === 'AtRisk') {
      // this.TabName = 'Local News Landscape'
      // setTimeout(() => {
      this.router.navigateByUrl('/localnewslandscape')
      // }, 100);
      // if (this.chart1Completed) {
      //   return
      // }
      // this.chart1Completed = true
      // this.Loading = true
      // setTimeout(() => {
      //   this.StateDataArr.consolidations.forEach((element: any) => {
      //     element.totalCirculation = Number(element.countOfNewspapers) + Number(element.countOfDigitalSites) + Number(element.countOfEthnicVoice) + Number(element.countOfPBStation)
      //     element.fips = Number(element.fips)
      //     Object.assign(element, { value: Number(element.countOfNewspapers) })
      //   });
      //   this.MapId = 'RiskCityMapSVG'
      //   this.MapFormation(this.StateDataArr.consolidations)
      // }, 200);
    }
    else if (Name === 'NewsPaper') {

      // this.TabName = 'Newspaper Count'
      // setTimeout(() => {
      this.router.navigateByUrl('/newspapers')

      // }, 100);
      // if (this.chart2Completed) {
      //   return
      // }

      // this.chart2Completed = true
      // this.Loading = true
      // setTimeout(() => {

      //   this.StateDataArr.consolidations.forEach((element: any) => {
      //     element.totalCirculation = Number(element.countOfNewspapers)
      //     element.fips = Number(element.fips)
      //     Object.assign(element, { value: Number(element.countOfNewspapers) })
      //   });
      //   this.MapId = 'NewsPaperMapSVG'
      //   this.MapFormation(this.StateDataArr.consolidations)
      // }, 200);
    } else if (Name === 'DigitalSite') {
      // this.TabName = 'Digital Sites Count'
      // setTimeout(() => {
      this.router.navigateByUrl('/digitalsites')

      // }, 100);
      // if (this.chart3Completed) {
      //   return
      // }
      // this.chart3Completed = true
      // this.Loading = true
      // setTimeout(() => {
      //   this.StateDataArr.consolidations.forEach((element: any) => {
      //     element.totalCirculation = Number(element.countOfDigitalSites)
      //     element.fips = Number(element.fips)
      //     Object.assign(element, { value: element.countOfDigitalSites })
      //   });
      //   this.MapId = 'digitalSitesMapSVG'

      //   this.MapFormation(this.StateDataArr.consolidations)
      // }, 200);
    }
    else if (Name === 'Ethnic') {

      // this.TabName = 'Ethnic Outlets Count'
      // setTimeout(() => {
      this.router.navigateByUrl('/ethnicoutlets')

      // }, 100);
      // if (this.chart4Completed) {
      //   return
      // }
      // this.chart4Completed = true
      // this.Loading = true
      // setTimeout(() => {

      //   this.StateDataArr.consolidations.forEach((element: any) => {
      //     element.totalCirculation = Number(element.countOfEthnicVoice)
      //     element.fips = Number(element.fips)
      //     Object.assign(element, { value: Number(element.countOfEthnicVoice) })
      //   });
      //   this.MapId = 'EthnicVoiceMapSVG'

      //   this.MapFormation(this.StateDataArr.consolidations)
      // }, 200);
    }
    else if (Name === 'Pb') {
      // this.TabName = 'Public Broadcasting Count'
      // setTimeout(() => {
      this.router.navigateByUrl('/publicbroadcasting')
      // return
      //       }, 100);
      // if (this.chart5Completed) {
      //   return
      // }
      // this.chart5Completed = true
      // this.Loading = true
      // setTimeout(() => {

      //   this.StateDataArr.consolidations.forEach((element: any) => {
      //     element.totalCirculation = Number(element.countOfPBStation)
      //     element.fips = Number(element.fips)
      //     Object.assign(element, { value: Number(element.countOfPBStation) })
      //   });
      //   this.MapId = 'PubliCProdCastMapSVG'

      //   this.MapFormation(this.StateDataArr.consolidations)
      // }, 200);
    }
    // else {
    //   this.TabName = 'Newspaper Count'
    //   if (this.chart1Completed) {
    //     return
    //   }
    //   this.chart1Completed = true;
    //   this.Loading = true
    //   setTimeout(() => {
    //   this.StateDataArr.consolidations.forEach((element: any) => {
    //     element.totalCirculation = Number(element.countOfNewspapers)
    //     element.fips = Number(element.fips)
    //     Object.assign(element, { value: Number(element.countOfNewspapers) })
    //   });
    //   this.MapId = 'NewsPaperMapSVG'

    //   this.MapFormation(this.StateDataArr.consolidations)
    // }, 200);

    // }


  }

  stateChange(event: any, state: any) {
    if (event.source.selected) {
      setTimeout(() => {
        let stateCode = state.StateCode
        let navigationExtras: NavigationExtras = {
          queryParams: { 'state': state.StateAbbr, stateCode },
        };
        if (this.routerValue == 'localnewslandscape') {
          this.router.navigate(['state-localnewslandscape'], navigationExtras);
        } else if (this.routerValue == 'newspapers') {
          this.router.navigate(['state-newspapers'], navigationExtras);
        } else if (this.routerValue == 'digitalsites') {
          this.router.navigate(['state-digitalsites'], navigationExtras);
        } else if (this.routerValue == 'ethnicoutlets') {
          this.router.navigate(['state-ethnicoutlets'], navigationExtras);
        } else if (this.routerValue == 'publicbroadcasting') {
          this.router.navigate(['state-publicbroadcasting'], navigationExtras);
        }
        window.scrollTo(0, 0);
      }, 50);

    }

  }
  public getJSON(): Observable<any> {
    // return this.http.get("/projects/state-of-local-news/explore/assets/stateData.json"); // this.StateDataArr = data
    return this.http.get("https://www.northwesternlni.com:8068/lni/consolidation?year=2024"); //this.StateDataArr.consolidations = data
    // return this.http.get("http://50.19.62.115:8067/lni/consolidation?year=2024");
  }

  fibsCount = [];


  radioChangeManual(radioValue: any) {
    d3.select('#tooltip').remove();
    d3.select("#RiskCityMapSVG").remove();
    d3.select('.RiskCityMapSVG').append("div")
      .attr("id", 'RiskCityMapSVG')
    if (radioValue.value === 'Local News Landscape') {
      this.router.navigateByUrl('/localnewslandscape')
    } else if (radioValue.value === 'Watch List Counties') {
      this.router.navigateByUrl('/watchlist')
    }
    else if (radioValue.value === 'Metro Areas') {
      this.router.navigateByUrl('/metroareanews')
    } else {
      this.router.navigateByUrl('/localnewsecosystembarometer')
    }
  }

  getPaperDetailes() {
    this.getJSON().subscribe((data: any) => {
      this.StateDataArr.consolidations = data;
      // this.StateDataArr.consolidations.forEach((element: any) => {
      //   if(element.state == 'VA' && element.county == 'Suffolk city'){
      //     console.log(element);
      //   }
      //   Object.assign(element, { value: element.countOfNewspapers })
      // });
      const consolidationArray = this.StateDataArr.consolidations.map((element: any) => {
        if (element.state == 'VA') {
          if (element.county == 'Suffolk city') {
            Object.assign(element, { fips: 51800 })
          } else if (element.county == 'Virginia Beach city') {
            Object.assign(element, { fips: 51810 })
          } else if (element.county == 'Newport News City') {
            Object.assign(element, { fips: 51700 })
          } else if (element.county == 'Norfolk city County') {
            Object.assign(element, { fips: 25021 })
          }
        }
        Object.assign(element, { value: element.countOfNewspapers })
        return element;
      });

      const URL_COUNTY = `${this.BaseURL}assets/counties.json`;
      const YOUR_JSON_URL = `${this.BaseURL}assets/UsEdu.json`;

      // const URL_COUNTY = "/projects/state-of-local-news/explore/assets/counties.json";
      // const YOUR_JSON_URL = "/projects/state-of-local-news/explore/assets/UsEdu.json";

      let promises: any = [];

      function addJsonReqPromise(url: any) {
        promises.push(d3.json(url));
      }

      addJsonReqPromise(URL_COUNTY);
      addJsonReqPromise(YOUR_JSON_URL);
      Promise.all(promises).then((data) => {
        const CountyFromGeoTable: any = [];
        const fibsArray = data[1].map((element: any) => {
          if (element.state === 'VA' && element.area_name === 'Richmond City' || element.state === 'VA' && element.area_name === 'Hopewell City') {
            element.area_name = element.area_name + ' County';
          }
          return element;
        });

        this.geoData = data[0];
        this.fibsCount = fibsArray;
        this.MapFormation1(consolidationArray);
      });
    });
  }
  MapId = 'RiskCityMapSVG';

  MapFormation(Data: any) {
    this.MapFormation1(Data)
  }

  MapFormation1(Data: any) {
    let dataEducation = Data;

    const CountyFromGeoTable: any = [];

    var fibsCount = this.fibsCount;

    let i = 0;

    const CountyFromAPItable: any = []
    dataEducation.forEach((element: any) => {
      if (this.RadioOptionValue === 'Local News Landscape') {
        if (element.state == 'AK' || element.state == 'LA') {
          element.county = element.county;
        } else if (!element.county.includes(' County')) {
          element.county = element.county + ' County'
        }

        // if(element.state == 'TX'){
        //   if(element.county == 'Smith County'){
        //     Object.assign(element, { fips: 48423 })
        //     // element.fips = 48423;
        //   }
        // }
      }
      const Obj = {
        CountyFromAPI: element.county
      }
      CountyFromAPItable.push(Obj)
    })

    // fibsCount.forEach((element: any) => {
    //   console.log(element.area_name);
    //   if (element.state == 'TX') {
    //     console.log("fibs ==> " + element.fips + " ==> " + element.area_name);
    //   }
    // })

    dataEducation.forEach((element: any) => {
      // console.log(element);
      // console.log(" data ==> " + element.fips + " ==> " + element.county);
      element.county = getMatchCounty(element.state, element.county);
      var matchFound: any = fibsCount.find((ele: any) => ele.state === element.state && (element.county === ele.area_name || ele.area_name === element.county))
      // } else {
      //   var matchFound: any = fibsCount.find((ele: any) => ele.state === element.state && (element.county == (ele.area_name) || ele.area_name == (element.county)))
      // }
      // var matchFound: any = fibsCount.forEach((ele: any) => {
      //   if(ele.state === element.state && (element.county == (ele.area_name) || ele.area_name == (element.county))){
      //     return ele;
      //   }
      // })
      // console.log(matchFound);
      if (matchFound) {
        Object.assign(element, { fips: matchFound.fips })
      } else {
        i = i + 1
      }
    });


    let geoData: any = this.geoData;


    let colors: any = d3.schemeBlues[7];



    function getCountyByFips(fips: any) {
      var county = dataEducation.find((county: any) => county.fips === fips);
      return county;
    }

    let svg = d3.select(`#${this.MapId}`)
      .append("svg")
      .attr("width", this.width)
      .attr("height", this.height)
      .attr("viewBox", `${this.ViewLeft} 0 ${this.ViewWidth} ${this.ViewHeight}`); // Set the viewBox to match the SVG dimensions
    this.SVG = svg
    const g = svg.append("g")
    this.MapG = g
    const newTransform = d3.zoomIdentity.scale(this.zoomLevel);
    this.MapG.attr('transform', newTransform);
    this.restoreTransform = newTransform;
    let geojson: any = topojson.feature(geoData, geoData.objects?.counties)

    geojson.features.forEach((element: any) => {
      var county: any = fibsCount.find((county: any) => county.fips === element.id);
      if (county) {
        Object.assign(element, { state: county.state })
        Object.assign(element, { area_name: county.area_name })
      }
    });
    let idx1 = 1
    function getCountyByFipsForColor(fips: any, arename: any, state: any) {
      var county = dataEducation.find((data: any) => (data.county.toLowerCase() === arename.toLowerCase() && data.state.toLowerCase() === state.toLowerCase()));
      if (!county || county == 'undefined' || county == 'null') {
        idx1 = idx1 + 1
      }
      return county;
    }

    let path: any = d3.geoPath();

    let idx = 0;
    const tooltip: any = d3.select(`#${this.MapId}`).append("div")
      .attr("class", "dataTip")
      .attr("style", " position: absolute")
      // .attr("style", " display: none")

      .attr("id", "tooltip")
    g.selectAll("path")
      .data(geojson.features)
      .enter()
      .append("path")
      .attr("d", path)
      .attr("class", "county")
      .attr("name", (d: any) => {
        let country = getCountyByFips(d.id)
        return country?.state
      })
      .style('stroke', '#7d91a6')
      .style("stroke-width", "0.25px")
      .attr("fill", (d: any) => {
        let country = getCountyByFipsForColor(d.id, d.area_name, d.state)

        if (this.MapId === 'RiskCityMapSVG') {
          if (country == undefined) {

            idx = idx + 1
            return '#fff'
          } else {
            if (this.RadioOptionValue === 'Local News Landscape') {
              const total = Number(country?.countOfNewspapers) + Number(country?.countOfDigitalSites) + Number(country?.countOfEthnicVoice) + Number(country?.countOfPBStation);
              return this.colorCountry(total);
            }
            else if (this.RadioOptionValue === 'Watch List Counties') {
              if (country?.category === 'Desert') {
                return '#fffff'
              } else {
                return '#412069'
              }
            }
            else if (this.RadioOptionValue === 'Metro Areas') {
              if (country?.digitalReplacement === 'Light Blue') {
                return '#80758e'
              } else {
                return '#412069'
              }

            }
            else {
              if (country?.value === 'More At Risk') {
                return '#412069'
              } else {
                return '#80758e'
              }
            }
          }
        }
        else if (this.MapId === 'NewsPaperMapSVG') {
          if (country == undefined) {

            return '#fff'
          } else {
            return this.colorCountry(country?.countOfNewspapers);
          }

        }


        else if (this.MapId === 'digitalSitesMapSVG') {
          if (country == undefined) {
            return '#fff'
          } else {
            return this.colorCountry(country?.countOfDigitalSites);
          }

        }
        else if (this.MapId === 'EthnicVoiceMapSVG') {
          if (country == undefined) {
            return '#fff'
          } else {
            return this.colorCountry(country?.countOfEthnicVoice);

          }
        } else {
          if (country == undefined) {
            return '#fff'
          } else {
            return this.colorCountry(country?.countOfPBStation);
          }
        }
      })
      .attr("data-fips", (d: any) => d.id)
      .on("mouseover", (d: any) => {
        // const highlightClass = 'highlighted-county';
        // console.log(d.target.__data__.id);
        let country = getCountyByFips(d.target.__data__.id)
        if (!country) {
          const tooltip = d3.select(".dataTip");
          tooltip.style("display", "none");
          return
        } else {
          const tooltip = d3.select(".dataTip");
          tooltip.style("display", "block");
        }

        // console.log(country?.fips);
        if (country?.state) {
          const matchingCounties = document.querySelectorAll(`[name="${country?.state}"]`);
          matchingCounties.forEach((element: any) => {
            element.style.opacity = "0.5";
            element.transition = "fill 0.1s";
          });
        }

        if (this.TabName === 'Local News Landscape') {

          if (this.RadioOptionValue === 'Local News Landscape') {
            const total = Number(country?.countOfNewspapers) + Number(country?.countOfDigitalSites) + Number(country?.countOfEthnicVoice) + Number(country?.countOfPBStation)
            tooltip.html(
              '<div class="dataTipHeader"> County :  ' + country?.county + ', ' + country?.state + '</div>'
              + '<div class="dataTipCount">' + '<span>' + 'Newspapers' + '</span>' + '<span class="count">' + country?.countOfNewspapers + '</span>' + '</div>'
              + '<div class="dataTipCount">' + '<span>' + 'Digital Sites' + '</span>' + '<span class="count">' + country?.countOfDigitalSites + '</span>' + '</div>'
              + '<div class="dataTipCount">' + '<span>' + 'Ethnic Outlets' + '</span>' + '<span class="count">' + country?.countOfEthnicVoice + '</span>' + '</div>'
              + '<div class="dataTipCount">' + '<span>' + 'Public Broadcasting' + '</span>' + '<span class="count">' + country?.countOfPBStation + '</span>' + '</div>'
              + '<div class="dataTipCount totalOutlets">' + '<span>' + 'Total no. of news outlets' + '</span>' + '<span class="count">' + total + '</span>' + '</div>'
            )
          } else if (this.RadioOptionValue === 'Watch List Counties') {
            tooltip.html(
              '<div class="dataTipHeader"> County :  ' + country?.county + ', ' + country?.state + '</div>'
              + '<div class="dataTipCount">' + '<span>' + 'Average poverty rate' + '</span>' + '<span class="count">' + country?.averagePovertyRate + '</span>' + '</div>'
              + '<div class="dataTipCount">' + '<span>' + 'Population' + '</span>' + '<span class="count">' + this.decimalPipe.transform(country?.population) + '</span>' + '</div>'

            )
          } else if (this.RadioOptionValue === 'Metro Areas') {

            tooltip.html(
              '<div class="dataTipHeader"> County :  ' + country?.county + ', ' + country?.state + '</div>'
              + '<div class="dataTipCount">' + '<span>' + 'Population' + '</span>' + '<span class="count">' + this.decimalPipe.transform(country?.population) + '</span>' + '</div>'
              + '<div class="dataTipCount">' + '<span>' + 'Number of news sites lost' + '</span>' + '<span class="count">' + country?.numberOfNewsSitesLost + '</span>' + '</div>'
              + '<div class="dataTipCount">' + '<span>' + 'News sites added' + '</span>' + '<span class="count">' + country?.newsSitesAdded + '</span>' + '</div>'
              + '<div class="dataTipCount">' + '<span>' + 'Total active news outlets' + '</span>' + '<span class="count">' + country?.totalActiveNewsOutlets + '</span>' + '</div>'
            )
          } else {
            tooltip.html(
              '<div class="dataTipHeader"> County :  ' + country?.county + ', ' + country?.state + '</div>'

            )
          }
        }

        else if (this.TabName === 'Newspaper Count') {

          tooltip.html(
            '<div class="dataTipHeader"> County :  ' + country?.county + ', ' + country?.state + '</div>'
            + '<div class="dataTipCount">' + '<span>' + this.TabName + '</span>' + '<span class="count">' + country?.countOfNewspapers + '</span>' + '</div>'
          )
        } else if (this.TabName === 'Digital Sites Count') {
          tooltip.html(
            '<div class="dataTipHeader"> County :  ' + country?.county + ', ' + country?.state + '</div>'
            + '<div class="dataTipCount">' + '<span>' + this.TabName + '</span>' + '<span class="count">' + country?.countOfDigitalSites + '</span>' + '</div>'
          )

        }
        else if (this.TabName === 'Ethnic Outlets Count') {
          tooltip.html(
            '<div class="dataTipHeader"> County :  ' + country?.county + ', ' + country?.state + '</div>'
            + '<div class="dataTipCount">' + '<span>' + this.TabName + '</span>' + '<span class="count">' + country?.countOfEthnicVoice + '</span>' + '</div>'
          )

        } else {
          tooltip.html(
            '<div class="dataTipHeader"> County :  ' + country?.county + ', ' + country?.state + '</div>'
            + '<div class="dataTipCount">' + '<span>' + this.TabName + '</span>' + '<span class="count">' + country?.countOfPBStation + '</span>' + '</div>'
          )

        }


        d3.select('tooltip')
          .style("stroke", "#000")
          .style("stroke-width", "2px");
        const tooltipHeight = tooltip.node().offsetHeight;
        let tooltipX = 0

        if (d.screenX + 350 >= this.width) {

          tooltipX = d.screenX - 350;
        }
        else {
          tooltipX = d.screenX + 10;
        }
        let tooltipY = d.screenY - tooltipHeight - 10;

        if (tooltipY < 0) {
          tooltipY = d.screenY + 10;
        }

        if (tooltipY > this.height) {
          tooltipY = tooltipY - 100
        }

        if (tooltipX < 0) {
          tooltipX = 0
        }
        if (tooltipX + 300 > this.MapContainerWidth) {
          tooltipX = this.MapContainerWidth - 300
        }

        tooltip.transition().style('opacity', 1)
          .style('left', tooltipX + 'px')
          .style('top', tooltipY + 'px')
          .style('box-shadow', '0px 0px 9px 2px rgba(0, 0, 0, 0.05)')
          .style('background-color', '#fff')
          .style('border-radius', '8px')
          .style('font-family', 'akkuratregular')
          .style('font-size', '16px');

      })

      .on("mouseout", (d: any) => {
        const allCounties = document.querySelectorAll(".county");
        allCounties.forEach((element: any) => {
          element.style.opacity = "1";
        });
      })
      .on("click", (d: any) => {
        let country1 = getCountyByFips(d.target.__data__.id)



        const country = this.StateNameList.find((element: any) => element.StateAbbr == country1?.state)
        if (country) {
          routingMap(country1?.state, country?.StateCode)
        }

      })


    const routingMap = (stateShotName: any, stateCode: any) => {
      let navigationExtras: NavigationExtras = {
        queryParams: { 'state': stateShotName, stateCode },
      };
      if (this.routerValue == 'localnewslandscape') {
        this.router.navigate(['state-localnewslandscape'], navigationExtras);
      } else if (this.routerValue == 'newspapers') {
        this.router.navigate(['state-newspapers'], navigationExtras);
      } else if (this.routerValue == 'digitalsites') {
        this.router.navigate(['state-digitalsites'], navigationExtras);
      } else if (this.routerValue == 'ethnicoutlets') {
        this.router.navigate(['state-ethnicoutlets'], navigationExtras);
      } else if (this.routerValue == 'publicbroadcasting') {
        this.router.navigate(['state-publicbroadcasting'], navigationExtras);
      }
      window.scrollTo(0, 0);
    }


    let borders = g.append("path")
      .classed("stateBorder", true)
      .attr("fill", "none")
      .attr("stroke", "#bec4db")
      .attr("stroke-width", "1.5px")
      .datum(topojson.mesh(geoData, geoData.objects.states))
      .attr('d', path)
      .filter(function (d: any) {
        return d === svg.node();
      });


    let debounceTimeout: any = null;

    const zoomed = (event: any) => {
      if (event?.sourceEvent?.type === 'wheel') {
        // event?.sourceEvent?.preventDefault();
        return

      }
      if (event?.sourceEvent?.type === 'dblclick') {
        // If there's a debounce timeout, clear it
        if (debounceTimeout) {
          clearTimeout(debounceTimeout);
          debounceTimeout = null;
        }

        // Handle the double-click action here
        const { transform } = event;
        this.TransFormedValue = transform;
        g.attr("transform", transform);
        g.attr("stroke-width", 1);
      } else {
        // If not a double-click, initiate a debounce timeout
        debounceTimeout = setTimeout(() => {
          const { transform } = event;
          this.TransFormedValue = transform;
          g.attr("transform", transform);
          g.attr("stroke-width", 1);
          debounceTimeout = null; // Reset the debounce timeout
        }, 50); // Adjust the debounce time as needed
      }
    };



    const zoomEnd = (event: any) => {
      this.currentTransform = event.transform;
    }

    this.originalTransform = d3.zoomIdentity;

    const zoom: any = d3.zoom()
      .scaleExtent([1, 8])
      .on("zoom", zoomed.bind(this))
      .on("end", zoomEnd.bind(this));
    this.ZOOM = zoom
    svg.call(zoom);

    setTimeout(() => {
      this.Loading = false
    }, 500);



    // })




  }

  ZOOM: any = ''
  TransFormedValue = 0;

  currentTransform = 0;

  originalTransform: any

  allowZooming: any = false

  colorCountry(country: any) {

    if (Number(country) == 1) {
      return '#80758e';
    }
    else if (Number(country) > 1) {
      return '#412069';
    }
    else {
      // console.log(this.TabName); 
      if (this.CurrentTabName === 'publicbroadcasting' || this.CurrentTabName === 'digitalsites' || this.CurrentTabName === 'ethnicoutlets') {
        return '#FFFFFF';
      } else {
        // return '#FFFFFF';
        return '#FFE59C';
      }
    }
  }


  chartConfig1() {
    const canvas = document.getElementById('MyChart1') as HTMLCanvasElement;

    this.chart1 = new Chart(canvas, {
      type: 'bar',
      data: {
        labels: ['2017', '2018', '2019', '2020', '2021'],
        datasets: [
          {
            label: 'Total Transactions',
            data: [50, 60, 40, 30, 35],
            borderWidth: 1,
            borderColor: '#fff',
            backgroundColor: '#F6C74B',
            borderRadius: 10,
            barPercentage: 0.8, // Adjust the width of the bars for the first dataset
            // categoryPercentage: 0.4, // Adjust the space between categories for the first dataset
          },
          {
            label: 'Number of Papers',
            data: [100, 70, 150, 100, 60],
            borderWidth: 1,
            borderColor: '#fff',
            backgroundColor: '#DD6049',
            borderRadius: 10,
            barPercentage: 0.8, // Adjust the width of the bars for the first dataset
            // Adjust the width of the bars for the second dataset
            // categoryPercentage: 0.4, // Adjust the space between categories for the second dataset
          },
        ],
      },
      options: {
        // aspectRatio: 2.5,
        scales: {
          x: {
            beginAtZero: true,
            grid: {
              display: false, // Remove y-axis grid lines
            },
            ticks: {
              color: '#22222280',
              font: {
                size: 12,
                family: 'akkuratregular',
                weight: '600',
              },
            },
            border: {
              display: false, // Remove x-axis border
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false, // Remove y-axis grid lines
            },
            ticks: {
              color: '#22222280',
              font: {
                size: 12,
                family: 'akkuratregular',
                weight: '600',
              },
            },
            border: {
              display: false, // Remove x-axis border
            },
          },
        },
        responsive: false,
        animation: {
          duration: 1000,
          easing: 'linear',
        },
        layout: {
          padding: 0,
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            align: 'center',
            fullSize: true,
            display: true,
            labels: {
              usePointStyle: true,
              pointStyle: 'circle',
              boxWidth: 12,
              boxHeight: 12,
              padding: 32,
              color: '#222222',
              font: {
                size: 13,
                family: 'akkuratregular',
                weight: '600',
                lineHeight: '15px',
              },
            },
          },
        },
      },
    });

    this.chart1.update();
  }

  chartConfig2() {
    const canvas = document.getElementById('MyChart2') as unknown as HTMLCanvasElement;

    this.chart2 = new Chart(canvas, {
      type: 'line',
      data: {
        labels: ['Other ', 'Accounting', 'Production', 'Sales', 'Newsroom'],
        datasets: [
          {
            label: '2006',
            data: [140000, 30000, 110000, 80000, 90000],
            borderWidth: 3,
            borderColor: '#DD6049',
            backgroundColor: '#DD6049',
            spanGaps: true,

          },
          {
            label: '2021',
            data: [50000, 10000, 30000, 30000, 40000],
            borderWidth: 3,
            borderColor: '#F6C74B',
            backgroundColor: '#F6C74B',
          },
        ],
      },
      options: {
        aspectRatio: 2.5,
        scales: {
          x: {
            ticks: {
              color: '#22222280',
              font: {
                size: 12,
                family: 'Akkurat',
                weight: '400',
              },
            },
            border: {
              display: false, // Remove x-axis border
            },
          },
          y: {
            beginAtZero: true,

            grid: {
              display: false, // Remove y-axis grid lines
            },
            ticks: {
              color: '#22222280',
              font: {
                size: 12,
                family: 'akkuratregular',
                weight: '600',
              },
            },

          },
        },
        responsive: false,
        animation: {
          duration: 1000,
          easing: 'linear',
        },
        layout: {
          padding: 0,

        },
        elements: {
          point: {
            radius: 0,
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            align: 'center',
            fullSize: true,
            // lineWidth: 10,
            display: true,
            labels: {
              usePointStyle: true,
              pointStyle: 'circle',
              boxWidth: 8,

              // lineWidth: 10,
              boxHeight: 8,
              padding: 32,
              color: '#222222',

              font: {
                size: 1,
                family: 'akkuratregular',
                weight: '600',
                lineHeight: '15px',

                // boxWidth:50,
              }
            }
          }
        }
      },
    });
    this.chart2.update();
  }
  Scroll(postion: any) {

    const element: any = document.getElementById('pills-tab');
    if (postion === 'right') {
      element?.scrollBy({ left: 100, behavior: 'smooth' }); // for right scroll
    } else {
      element?.scrollBy({ left: -100, behavior: 'smooth' });
    }
  }



  radioChange(radioValue: any) {


    d3.select('#tooltip').remove();
    d3.select("#RiskCityMapSVG").remove();
    d3.select('.RiskCityMapSVG').append("div")
      .attr("id", 'RiskCityMapSVG')
    if (radioValue.value === 'Local News Landscape') {
      this.MapId = 'RiskCityMapSVG'
      this.MapFormation1(this.StateDataArr.consolidations)
    } else if (radioValue.value === 'Watch List Counties') {
      this.getWatchListData();
    }
    else if (radioValue.value === 'Metro Areas') {
      this.getMetroListData();
    } else {
      this.getEcometerData();
    }

    this.callTopBottomContent()

  }



  getWatchListData() {
    this.Loading = true
    this.lniService.getWatchListData().subscribe((response: any) => {
      response.watchlistCounties.forEach((element: any) => {
        element.totalCirculation = Number(element.countOfDigitalSites)
        element.fips = Number(element.fips)
        Object.assign(element, { value: element.category })
        Object.assign(element, { county: element.countyName })
      });
      this.MapId = 'RiskCityMapSVG'

      const URL_COUNTY = "/projects/state-of-local-news/explore/assets/counties.json";
      const YOUR_JSON_URL = "/projects/state-of-local-news/explore/assets/UsEdu.json";

      let promises: any = [];

      function addJsonReqPromise(url: any) {
        promises.push(d3.json(url));
      }

      addJsonReqPromise(URL_COUNTY);
      addJsonReqPromise(YOUR_JSON_URL);

      Promise.all(promises).then((data) => {
        const CountyFromGeoTable: any = [];
        data[1].forEach((element: any) => {
          if (!element.area_name.includes(' County')) {
            element.area_name = element.area_name + ' County'

          }
          element.area_name = element.area_name.split(' County')[0]
          const Obj = {
            CountyFromGeo: element.area_name
          }
          CountyFromGeoTable.push(Obj)
        });
        this.geoData = data[0];
        this.fibsCount = data[1];
        this.MapFormation1(response.watchlistCounties)
      })

      // this.MapFormation1(data.watchlistCounties)
    });
  }

  getMetroListData() {
    this.Loading = true
    this.lniService.getMetroListData().subscribe((response: any) => {

      response.metroAreas.forEach((element: any) => {
        element.totalCirculation = Number(element.countOfDigitalSites)
        element.fips = Number(element.fips)
        Object.assign(element, { value: element.digitalReplacement })
        Object.assign(element, { county: element.countyName })

      });
      this.MapId = 'RiskCityMapSVG'



      const URL_COUNTY = "/projects/state-of-local-news/explore/assets/counties.json";
      const YOUR_JSON_URL = "/projects/state-of-local-news/explore/assets/UsEdu.json";

      let promises: any = [];

      function addJsonReqPromise(url: any) {
        promises.push(d3.json(url));
      }

      addJsonReqPromise(URL_COUNTY);
      addJsonReqPromise(YOUR_JSON_URL);

      Promise.all(promises).then((data) => {
        const CountyFromGeoTable: any = [];
        data[1].forEach((element: any) => {
          if (!element.area_name.includes(' County')) {
            element.area_name = element.area_name + ' County'

          }
          element.area_name = element.area_name.split(' County')[0]
          const Obj = {
            CountyFromGeo: element.area_name
          }
          CountyFromGeoTable.push(Obj)
        });
        this.geoData = data[0];
        this.fibsCount = data[1];

        this.MapFormation1(response.metroAreas)
      })
    });
  }


  getEcometerData() {
    this.Loading = true
    this.lniService.getEcometerData().subscribe((response: any) => {
      response.localNewsEcosystemBarometers.forEach((element: any) => {
        element.totalCirculation = Number(element.countOfDigitalSites)
        element.fips = Number(element.fips)
        Object.assign(element, { value: element.value })
        Object.assign(element, { county: element.countyName })

      });
      this.MapId = 'RiskCityMapSVG'
      const URL_COUNTY = "/projects/state-of-local-news/explore/assets/counties.json";
      const YOUR_JSON_URL = "/projects/state-of-local-news/explore/assets/UsEdu.json";

      let promises: any = [];

      function addJsonReqPromise(url: any) {
        promises.push(d3.json(url));
      }

      addJsonReqPromise(URL_COUNTY);
      addJsonReqPromise(YOUR_JSON_URL);

      Promise.all(promises).then((data) => {
        const CountyFromGeoTable: any = [];
        data[1].forEach((element: any) => {
          if (!element.area_name.includes(' County')) {
            element.area_name = element.area_name + ' County'

          }
          element.area_name = element.area_name.split(' County')[0]
          const Obj = {
            CountyFromGeo: element.area_name
          }
          CountyFromGeoTable.push(Obj)
        });
        this.geoData = data[0];
        this.fibsCount = data[1];

        this.MapFormation1(response.localNewsEcosystemBarometers)

      })
      // this.MapFormation1(data.localNewsEcosystemBarometers)
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    d3.select("#tooltip").remove();
    d3.select("#RiskCityMapSVG").remove();
    d3.select("#NewsPaperMapSVG").remove();
    d3.select("#digitalSitesMapSVG").remove();
    d3.select("#EthnicVoiceMapSVG").remove();
    d3.select("#PubliCProdCastMapSVG").remove();
  }
  private zoomLevel: number = 1



  zoomOut() {
    this.allowZooming = true
    this.zoomLevel /= 1.2; // You can adjust the zoom factor as needed
    this.updateZoom();

  }

  MapG: any = '';

  restoreTransform: any = d3.zoomIdentity;

  SVG: any = '';

  updateZoom() {
    const newTransform = d3.zoomIdentity.scale(this.zoomLevel);

    this.MapG.attr('transform', newTransform);

    this.restoreTransform = newTransform;

  }

  zoomIN() {
    this.zoomLevel *= 1.2; // You can adjust the zoom factor as needed
    this.updateZoom();
    this.allowZooming = true
  }

  ReStore() {
    const newTransform1 = d3.zoomIdentity;
    this.SVG.transition().duration(1).call(this.ZOOM.transform, newTransform1);
    this.zoomLevel = 1;
    const newTransform = this.originalTransform;
    this.MapG.attr("transform", newTransform);
    this.TransFormedValue = newTransform;
    this.currentTransform = newTransform;

    this.zoomLevel = 1;
    this.allowZooming = true
    this.updateZoom();
    // this.MapG.attr("transform", `scale(1.5)`);


  }

  loadTabContent(Tabindex: any) { // for onload tabs
    this.getBannerContent('Banner');


    if (Tabindex === 0) {
      this.getBannerContent('TopLocal');
      this.getBannerContent('BottomLocal');
    }
    else if (Tabindex === 1) {
      this.getBannerContent('TopNews');
      this.getBannerContent('BottomNews');
    }
    else if (Tabindex === 2) {
      this.getBannerContent('TopDigital');
      this.getBannerContent('BottomDigital');
    }
    else if (Tabindex === 3) {
      this.getBannerContent('TopEthinic');
      this.getBannerContent('BottomEthinic');
    }
    else if (Tabindex === 4) {
      this.getBannerContent('TopPb');
      this.getBannerContent('BottomPb');
    }
  }


  callTopBottomContent() { //for radio tab
    if (this.RadioOptionValue === 'Local News Landscape') {
      this.getBannerContent('TopLocal');
      this.getBannerContent('BottomLocal');

    } else if (this.RadioOptionValue === 'Watch List Counties') {
      this.getBannerContent('Banner');
      this.getBannerContent('TopWatchList');
      this.getBannerContent('BottomWatchList');
    }
    else if (this.RadioOptionValue === 'Metro Areas') {
      this.getBannerContent('Banner');
      this.getBannerContent('TopMetro');
      this.getBannerContent('BottomMetro');
    }
    else if (this.RadioOptionValue === 'Local News Ecosystem Barometer') {
      this.getBannerContent('Banner');
      this.getBannerContent('TopBaro');
      this.getBannerContent('BottomBaro');
    }

  }


  BannerContent = '';
  BottomContentPB = '';
  BottomContentEthinic = '';
  BottomContentDigital = '';
  BottomContentNews = '';
  BottomContentLocal = '';

  TopContentPB = '';
  TopContentEthinic = '';
  TopContentDigital = '';
  TopContentNews = '';
  TopContentLocal = '';
  getBannerContent(type: any) {
    let location: any = '';
    let text: any = '';

    if (type === 'Banner') {
      location = 'Top Banner';
      text = null;
      this.BannerContent = ''
    }
    else if (type === 'TopLocal') {
      location = 'Local News Landscape Map';
      text = 'Top Text'
      this.TopContentLocal = ''
    }
    else if (type === 'BottomLocal') {
      location = 'Local News Landscape Map';
      text = 'Bottom Text';
      this.BottomContentLocal = ''
    }


    else if (type === 'BottomWatchList') {
      location = 'Watchlist Counties Map';
      text = 'Bottom Text';
      this.BottomContentLocal = '';
    }
    else if (type === 'TopWatchList') {
      location = 'Watchlist Counties Map';
      text = 'Top Text'
      this.TopContentLocal = ''
    }
    else if (type === 'BottomMetro') {
      location = 'Metro Areas Map';
      text = 'Bottom Text';
      this.BottomContentLocal = '';

    }
    else if (type === 'TopMetro') {
      location = 'Metro Areas Map';
      text = 'Top Text'
      this.TopContentLocal = ''
    }
    else if (type === 'BottomBaro') {
      const additionalLink = '<a href="your_link_here">Additional Link Text</a>';
      location = 'Local News Ecosystem Barometer';
      text = 'Bottom Text';
      this.BottomContentLocal = '';

    }
    else if (type === 'TopBaro') {
      location = 'Local News Ecosystem Barometer';
      text = 'Top Text'
      this.TopContentLocal = ''
    }
    else if (type === 'BottomNews') {
      location = 'National Newspapers Map';
      text = 'Bottom Text';
      this.BottomContentNews = '';
    }
    else if (type === 'TopNews') {
      location = 'National Newspapers Map';
      text = 'Top Text'
      this.TopContentNews = '';
    }
    else if (type === 'BottomDigital') {
      location = 'National Digital Sites Map';
      text = 'Bottom Text';
      this.BottomContentDigital = ''
    }
    else if (type === 'TopDigital') {
      location = 'National Digital Sites Map';
      text = 'Top Text'
      this.TopContentDigital = '';
    }
    else if (type === 'BottomEthinic') {
      location = 'National Ethnic Media Map';
      text = 'Bottom Text'
      this.BottomContentEthinic = '';
    }
    else if (type === 'TopEthinic') {
      location = 'National Ethnic Media Map';
      text = 'Top Text'
      this.TopContentEthinic = ''
    }
    else if (type === 'BottomPb') {
      location = 'National Public Broadcasters Map';
      text = 'Bottom Text';
      this.BottomContentPB = '';
    }
    else if (type === 'TopPb') {
      location = 'National Public Broadcasters Map';
      text = 'Top Text';
      this.TopContentPB = '';
    }
    this.lniService.getNationalInformation(location, text).subscribe({
      next: (response: any) => {
        if (type === 'Banner') {
          this.BannerContent = response.text
        } else if (type === 'BottomLocal') {
          this.BottomContentLocal = response.text
        }
        else if (type === 'TopLocal') {
          this.TopContentLocal = response.text
        }

        else if (type === 'BottomWatchList') {
          this.BottomContentLocal = response.text;
        }
        else if (type === 'TopWatchList') {
          this.TopContentLocal = response.text
        }
        else if (type === 'BottomMetro') {
          this.BottomContentLocal = response.text;

        }
        else if (type === 'TopMetro') {
          this.TopContentLocal = response.text
        }
        else if (type === 'BottomBaro') {
          this.BottomContentLocal = response.text;

        }
        else if (type === 'TopBaro') {
          this.TopContentLocal = response.text
        }
        else if (type === 'BottomNews') {
          this.BottomContentNews = response.text;
        }
        else if (type === 'TopNews') {
          this.TopContentNews = response.text;
        }
        else if (type === 'BottomDigital') {
          this.BottomContentDigital = response.text
        }
        else if (type === 'TopDigital') {
          this.TopContentDigital = response.text;
        }
        else if (type === 'BottomEthinic') {
          this.BottomContentEthinic = response.text;
        }
        else if (type === 'TopEthinic') {
          this.TopContentEthinic = response.text
        }
        else if (type === 'BottomPb') {
          this.BottomContentPB = response.text;
        }
        else if (type === 'TopPb') {
          this.TopContentPB = response.text;
        }
      },
      error: (error: any) => {
        // console.log(error);
      },
      complete: () => { },
    })
  }

}
function getMatchCounty(state: any, county: any): any {
  if (state == 'VA') {
    if (county == 'Richmond city County') {
      county = 'Richmond City County';
    } else if (county == 'Hopewell city County') {
      county = 'Hopewell City County';
    } else if (county == 'Colonial Heights city County') {
      county = 'Colonial Heights City';
    } else if (county == 'Petersburg city County') {
      county = 'Petersburg City';
    } else if (county == 'Emporia city County') {
      county = 'Emporia City';
    } else if (county == 'Danville city County') {
      county = 'Danville City';
    } else if (county == 'Charlottesville city County') {
      county = 'Charlottesville city';
    } else if (county == 'Lynchburg city County') {
      county = 'Lynchburg City';
    } else if (county == 'Roanoke city County') {
      county = 'Roanoke city';
    } else if (county == 'Salem city County') {
      county = 'Salem City';
    } else if (county == 'Waynesboro city County') {
      county = 'Waynesboro city';
    } else if (county == 'Staunton city County') {
      county = 'Staunton City';
    } else if (county == 'Harrisonburg city County') {
      county = 'Harrisonburg City';
    } else if (county == 'Martinsville city County') {
      county = 'Martinsville City';
    } else if (county == 'Norton city County') {
      county = 'Norton City';
    } else if (county == 'Radford city County') {
      county = 'Radford City';
    } else if (county == 'Galax city County') {
      county = 'Galax City';
    } else if (county == 'Bristol city County') {
      county = 'Bristol County';
    } else if (county == 'Chesapeake city County') {
      county = 'Chesapeake city';
    } else if (county == 'Frederick County') {
      county = 'Fredericksburg City';
    } else if (county == 'Fredericksburg city County') {
      county = 'Frederick County';
    } else if (county == 'Alexandria city County') {
      county = 'Alexandria city';
    } else if (county == 'Fairfax city County') {
      county = 'Fairfax City';
    } else if (county == 'Manassas Park city County') {
      county = 'Manassas City';
    } else if (county == 'Winchester city County') {
      county = 'Winchester City';
    } else if (county == 'Covington city County') {
      county = 'Covington City';
    } else if (county == 'Lexington city County') {
      county = 'Lexington County';
    } else if (county == 'Buena Vista city County') {
      county = 'Buena Vista City';
    } else if (county == 'Franklin County') {
      county = 'Franklin County';
    } else if (county == 'Franklin city County') {
      county = 'Franklin County';
    } else if (county == 'Williamsburg city County') {
      county = 'Williamsburg City';
    } else if (county == 'Suffolk city County') {
      county = 'Suffolk City';
    } else if (county == 'Virginia Beach city County') {
      county = 'Virginia Beach City';
    } else if (county == 'Hampton city County') {
      county = 'Hampton city';
    } else if (county == 'Newport News city County') {
      county = 'Newport News City';
    } else if (county == 'Norfolk city County') {
      county = 'Norfolk City';
    } else if (county == 'Portsmouth city County') {
      county = 'Portsmouth city';
    } else if (county == 'Poquoson city County') {
      county = 'Poquoson city';
    }
    return county;
  } else if (state == 'NV') {
    if (county == 'Carson City County') {
      county = 'Carson City';
    }
  } else if (state == 'MD') {
    if (county == 'Baltimore city County') {
      county = 'Baltimore city'
    } else if (county == 'Baltimore city') {
      county = 'Baltimore County';
    }
  } else if (state == 'TX') {
    if (county == 'Deaf Smith County') {
      county = 'Deaf Smith County';
    } else if (county == 'Smith County') {
      county = 'Smith County'
    }
  }
  return county;
}

