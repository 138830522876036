<div class="InnerContent bg-white" *ngIf="from ==='StatePage'">
    <div class="HomeEndSection ">
        <div class="chartHeader ">
            <span class="chartCaption ">Subscribe to our newsletter</span>
        </div>
        <div class="row g-1">
            <div class="col-xl-6 col-sm-12 col-md-12">
                <p class="update-text">
                    The State of Local News Project produces quarterly updates on changes and trends in the local news industry. To receive these updates, please join the Local News Initiative Mailing List by clicking on the button below.
                </p>
                <button class="updateButton" (click)="GotoHome()">
                    Sign up here
                </button>
            </div>
            <div class="col-xl-6 col-sm-12 col-md-12 d-flex align-items-center flex-wrap justify-content-center">
                 <div class="four col-md-3">
                    <div class="c-box" (click)="FooterLinks('Research')">
                        <img src="/projects/state-of-local-news/explore/assets/Images/research.svg" alt="" class="gp-icon">
                        <p>Research</p>
                    </div>
                </div>
                <div class="four col-md-3">
                    <div class="c-box" (click)="FooterLinks('Articles')">
                        <img src="/projects/state-of-local-news/explore/assets/Images/articles.svg" alt="" class="gp-icon">
                        <p>Articles</p>
                    </div>
                </div>
                <div class="four col-md-3">
                    <div class="c-box" (click)="FooterLinks('Resources')">
                        <img src="/projects/state-of-local-news/explore/assets/Images/resources.svg" alt="" class="gp-icon">
                        <p>Resources</p>
                    </div>
                </div>

                <div class="col-12 col-sm-12 col-md-12 d-flex align-items-center justify-content-center footer-logo">
                    <img src="/projects/state-of-local-news/explore/assets/Images/image 25.png" width="400" alt="" srcset="" class=" ">
                </div>
            </div>
        </div>
    </div>
</div>
<div class=" gContainerMain3" [ngClass]="{'gFooter': from ==='StatePage', 'gFooterLatest' : from !=='StatePage' }" >
    <div class="Footer1 ">
        <span class="SponsorTitle">Funders</span>
        <div class="row mt-3">
            <div *ngFor="let data of SponsoresArr " class="col-xl-3 col-md-4 col-sm-6 col-xs-3 fund">
                <div class="footerImgDiv widthFull">
                    <img src="{{data.Image}}" alt="" class="{{data.className}} ">
                </div>
            </div>
        </div>
        <!-- </owl-carousel-o> -->
    </div>
</div>
<div class="Footer2 gContainerMain3">
    <div class="row ">
        <div class="col-12 col-xl-8 footerSecondSection">
            <div class="d-flex flex-column  ">
                <div class="col-12 col-md-5 ">
                    <div class="footerImgContainer">
                        <!-- <img src="/projects/state-of-local-news/explore/assets/Images/footerlogo3.png" alt="" style="height: 78px;"> -->
                    </div>
                </div>
                <div class="col-12 ">
                    <p class="footer-text">
                        With local journalism in crisis, Northwestern University has assembled a team of experts in
                        digital innovation, audience understanding and business strategy. The goal: reinvent the
                        relationship between news organizations and audiences to elevate enterprises that empower
                        citizens.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-8 col-xl-4 footer-sub-links">
            <div class="link-text-1">Links</div>
            <ul class="headerList">
                <li><a href="https://localnewsinitiative.northwestern.edu/about/" target="_blank" >About Us</a></li>
                <li><a href="https://localnewsinitiative.northwestern.edu/research/" target="_blank">Research</a></li>
                <li><a href="https://northwestern.az1.qualtrics.com/jfe/form/SV_erjjVa4drHs9hnE" target="_blank">Contact Us</a></li>
            </ul>
        </div>
    </div>
    <hr style="color:#717171; ">

    <span class="copywriteText">
        © 2019-2023 Northwestern University
    </span>
</div>
